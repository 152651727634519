import { Dates } from 'helpers';
import { Customer } from 'services/customers';
import { PermissionType } from 'services/permissions';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export enum CustomerPageAction {
  Import,
  Export,
  CustomFields,
  ChangeDate,
}

export enum CustomerPageRowAction {
  Delete,
}

export const createActionBarOptions = (
  handleActionClick: (type: CustomerPageRowAction) => void,
  customer: Customer
): MenuItem[] => [
  {
    label: 'Delete Customer',
    onOptionClick: () => handleActionClick(CustomerPageRowAction.Delete),
    icon: IconNames.FBODeleteSmall,
    permissions: customer.deleted
      ? [PermissionType.Undelete]
      : [PermissionType.CustomersDelete],
    variant: MenuItemVariant.Danger,
    dataQa: `delete-customer-${customer?.name}`,
  },
];

export const createCustomersSearchActionBarOptions = (
  onShowReport: () => void,
  onActionClick: (actionType: CustomerPageAction, date?: Dates) => void,
  customersCount: number
): MenuItem[] => [
  {
    icon: IconNames.FBOReportSmall,
    label: 'Show Report',
    onOptionClick: onShowReport,
    separateAfter: true,
  },
  {
    label: 'Import Customers',
    icon: IconNames.FBOImport,
    iconRight: IconNames.FBOQuestionMarkInfo,
    iconRightHref:
      'https://help.fishbowlonline.com/s/article/Drive-Customers#Import_customers',
    onOptionClick: () => onActionClick(CustomerPageAction.Import),
    permissions: [PermissionType.CustomersEdit, PermissionType.CustomersCreate],
    separateAfter: true,
  },
  {
    label: 'Export Customers',
    icon: IconNames.FBOExport,
    onOptionClick: () => onActionClick(CustomerPageAction.Export),
    disabled: !customersCount,
    separateAfter: true,
  },
  {
    label: 'Custom Fields',
    icon: IconNames.FBOReportSmall,
    onOptionClick: () => onActionClick(CustomerPageAction.CustomFields),
    permissions: [PermissionType.CustomersEdit, PermissionType.CustomersCreate],
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(CustomerPageAction.ChangeDate, Dates.DateCreated),
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(CustomerPageAction.ChangeDate, Dates.DateLastModified),
      },
    ],
  },
];
