import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

import {
  fetchXeroTaxRates,
  InternationalTaxRate,
  getXeroTaxRates,
} from 'services/integrations/xero';
import { InternationalTaxRatesProps } from './types';
import {
  fetchQuickbookTaxRates,
  getQuickbooksTaxRates,
} from 'services/integrations/quickbooks';

export const InternationalTaxRates = (props: InternationalTaxRatesProps) => {
  const {
    accountingType,
    label,
    optionsFilter,
    selectedOptionCallback,
    index,
    selectedValue,
    dataQa,
  } = props;

  const [selectedTaxRate, setSelectedTaxRate] =
    useState<InternationalTaxRate>();

  const dispatch = useDispatch();
  const taxRates: InternationalTaxRate[] = useSelector(
    accountingType === 'XERO' ? getXeroTaxRates : getQuickbooksTaxRates
  );
  const loadInternationalTaxRates = () => {
    if (taxRates?.length <= 0) {
      if (accountingType === 'XERO') {
        // @ts-ignore
        dispatch(fetchXeroTaxRates());
      } else {
        // @ts-ignore
        dispatch(fetchQuickbookTaxRates());
      }
    }
  };
  useEffect(loadInternationalTaxRates, []);

  const selectedInternationalTaxRate = () => {
    if (selectedTaxRate) {
      return taxRates.find(
        (taxRate) =>
          taxRate.name === selectedTaxRate.name &&
          taxRate.taxType === selectedTaxRate.taxType &&
          taxRate.code === selectedTaxRate.code
      );
    }
    if (selectedValue) return selectedValue;
    return null;
  };

  const availableInternationalTaxRates = useCallback(() => {
    let options: InternationalTaxRate[];

    if (taxRates?.length > 0) {
      options = [...taxRates];

      if (selectedTaxRate)
        options = options.filter((o) => o.name !== selectedTaxRate.name);

      if (optionsFilter) options = options.filter(optionsFilter);
    } else {
      options = [];
    }

    return options;
  }, [taxRates, selectedTaxRate]);
  const handleInternationalTaxRateChange = (
    _e: React.SyntheticEvent<Element, Event>,
    value: InternationalTaxRate | null
  ) => {
    if (value) {
      setSelectedTaxRate(value);
      if (selectedOptionCallback) selectedOptionCallback(value, index);
    }
  };

  const getOptionsLabel = (xtr: InternationalTaxRate) =>
    xtr.name + ' (' + xtr.taxType + ')';

  return (
    <Autocomplete
      label={label}
      options={availableInternationalTaxRates()}
      value={selectedInternationalTaxRate()}
      getOptionLabel={getOptionsLabel}
      onChange={handleInternationalTaxRateChange}
      placeholder={`Select ${accountingType} Tax Rate`}
      dataQa={dataQa}
    />
  );
};
