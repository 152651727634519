import axios, { AxiosRequestConfig } from 'axios';
import decode from 'jwt-decode';

// common header config goes here because it has to be set before the instances
// are created
axios.defaults.headers.common['Client-Version'] =
  process.env.REACT_APP_CLIENT_VERSION ?? '';
axios.defaults.headers.common['Client-Id'] =
  process.env.REACT_APP_CLIENT_ID ?? '';
axios.defaults.headers.common['Client-Key'] =
  process.env.REACT_APP_CLIENT_KEY ?? '';

export const tenantIdHeaderInterceptor = (
  req: AxiosRequestConfig<{
    headers: { common: { Authorization: string } };
  }>
) => {
  req.headers = req.headers || {};
  try {
    const authHeader =
      // The axios type definitions don't reflect this for some reason
      (req.headers?.common as any).Authorization;
    const encodedToken = authHeader.split(' ')[1];
    const token = decode<{ fbo_tenant: string }>(encodedToken);
    req.headers['Tenant-Id'] = token.fbo_tenant;
    // eslint-disable-next-line no-empty
  } catch {}
  return req;
};

export const api = {
  hostname: `${process.env.REACT_APP_API_URL}`,
};

export const csvApi = {
  hostname: `${process.env.REACT_APP_CSV_SERVICE_API_URL}`,
};

export const commerceApi = axios.create({
  baseURL: `${process.env.REACT_APP_COMMERCE_URL}`,
});

export const reportingApi = axios.create({
  baseURL: `${process.env.REACT_APP_REPORTING_SERVICE_URL}`,
});

export const imageApi = axios.create({
  baseURL: `${process.env.REACT_APP_IMAGES_SERVICE_URL}`,
});

export const integrationsApi = axios.create({
  baseURL: `${process.env.REACT_APP_INTEGRATIONS_URL}`,
});

export const shippingIntegrationsApi = axios.create({
  baseURL: `${process.env.REACT_APP_SHIPPING_URL}`,
});

export const authenticationApi = axios.create({
  baseURL: `${process.env.REACT_APP_AUTHENTICATION_URL}`,
});
