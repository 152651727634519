import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import { RumRoute as Route } from '@datadog/rum-react-integration';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import lodash from 'lodash';
import clsx from 'clsx';
import { Box } from '@mui/material';

import SecureRoute from 'ui/components/Route/SecureRoute';
import { MaterialsRouter } from 'ui/modules/materials';
import { SetupRouter } from 'ui/modules/setup';
import { PurchasingRouter } from 'ui/modules/purchasing';
import { SalesRouter } from 'ui/modules/sales';
import { ReportsRouter } from 'ui/modules/reports';
import { HomeRouter } from 'ui/modules/home';
import { AccountingRouter } from 'ui/modules/accounting';
import { CommerceRouter } from 'ui/modules/commerce';
import { IntegrationsRouter } from 'ui/modules/integrations';
import { NotificationsRouter } from 'ui/modules/notifications';
import { SandboxRouter } from 'ui/modules/sandbox';
import { RemoteSupportRouter } from 'ui/modules/remotesupport';
import { FeedbackRouter } from 'ui/modules/feedback/navigation';
import { fetchActiveUser } from 'services/user';
import { fetchQuickbooksActiveAccount } from 'services/integrations/quickbooks/redux';
import { getAccessToken } from 'services/auth';
import { fetchShippingIntegrationConnections } from 'services/integrations/shipping/redux';
import { fetchSettingsCompanies } from 'services/settings/company/redux';
import { fetchXeroActiveAccount } from 'services/integrations/xero';

import { Routes as AppRoutes } from '../../navigation';
import { useAppHomeStyles } from './styled';

import { getActiveUser } from 'services/user';

import { fetchSellwareActiveAccount } from 'services/commerce';
import { useFlags } from 'helpers/useFlags';
import FBODrawer from 'ui/theme/components/FBODrawer/FBODrawer';
import { FBOAppBar } from 'ui/theme/components/FBOAppBar';
import { fetchPaymentTerms } from 'services/paymentTerms';
export interface AppHomeCmp extends React.FunctionComponent {
  route: string;
}

const AppHome: AppHomeCmp = (props: any) => {
  const classes = useAppHomeStyles(props);

  const dispatch = useDispatch();
  const location = useLocation();

  const activeUser = useSelector(getActiveUser);
  const ldClient = useLDClient();

  const flags = useFlags();

  const [drawerExpanded, setDrawerExpanded] = React.useState(true);
  const [drawerExpandedPersistent, setDrawerExpandedPersistent] =
    React.useState(true);

  const handleDrawerExpand = useCallback(
    (expanded: boolean, persistent: boolean) => {
      setDrawerExpanded(expanded);
      if (persistent) {
        setDrawerExpandedPersistent(expanded);
      }
    },
    []
  );

  useEffect(() => {
    if (ldClient && activeUser.userGlobalId && activeUser.user) {
      const key = lodash.get(activeUser, 'userGlobalId', '000') as string;
      const email = lodash.get(activeUser, 'user.email', 'n/a');
      const firstName = lodash.get(activeUser, 'user.firstName', 'n/a');
      const lastName = lodash.get(activeUser, 'user.lastName', 'n/a');
      const name = `${firstName} ${lastName}`;

      ldClient.identify({
        kind: 'user',
        key,
        email,
        name,
      });
    }
  }, [activeUser]);

  useEffect(() => {
    // if there's an access token in storage fetch current user
    const accessToken = getAccessToken();
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    if (accessToken) {
      dispatch(fetchActiveUser() as any);
      // @ts-ignore
      dispatch(fetchQuickbooksActiveAccount());
      // @ts-ignore
      dispatch(fetchShippingIntegrationConnections());
      // @ts-ignore
      dispatch(fetchSettingsCompanies());
      // @ts-ignore
      dispatch(fetchPaymentTerms());
      // Check for Xero integration
      // @ts-ignore
      dispatch(fetchXeroActiveAccount());

      // Check for Sellware integration
      if (flags.sellware) {
        // @ts-ignore
        dispatch(fetchSellwareActiveAccount());
      }
    }
  }, [dispatch]);

  return (
    <Box
      className={clsx('redesign', {
        [classes.homeWrapper]: false,
        'home-page-layout': true,
      })}
    >
      <FBODrawer
        drawerExpanded={drawerExpanded || drawerExpandedPersistent}
        drawerPersistent={drawerExpandedPersistent}
        onDrawerExpand={handleDrawerExpand}
        activePathName={location.pathname}
      />

      <Box
        className={clsx('main-page-layout', {
          [classes.contentWrapper]: false,
        })}
      >
        <FBOAppBar />
        <Switch>
          <Route path={MaterialsRouter.route} component={MaterialsRouter} />
          <Route path={SetupRouter.route} component={SetupRouter} />
          <Route path={PurchasingRouter.route} component={PurchasingRouter} />
          <Route path={AccountingRouter.route} component={AccountingRouter} />
          <Route path={SalesRouter.route} component={SalesRouter} />
          <Route path={ReportsRouter.route} component={ReportsRouter} />
          <Route
            path={NotificationsRouter.route}
            component={NotificationsRouter}
          />
          <SecureRoute
            featureFlag={'sellware'}
            path={CommerceRouter.route}
            component={CommerceRouter}
          />
          <Route
            path={IntegrationsRouter.route}
            component={IntegrationsRouter}
          />
          <Route
            path={RemoteSupportRouter.route}
            component={RemoteSupportRouter}
          />
          <Route path={FeedbackRouter.route} component={FeedbackRouter} />
          <Route path={SandboxRouter.route} component={SandboxRouter} />
          <Route path={HomeRouter.route} component={HomeRouter} />
        </Switch>
      </Box>
    </Box>
  );
};

AppHome.route = AppRoutes.AppHome;

export default AppHome;
