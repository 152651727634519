import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import {
  TrackingTableTypes,
  trackingTableValidationSchema,
} from 'ui/components/Table/TrackingTable';
import { SerialTableStatus } from 'ui/components/Table/SerialTable';
import { TextField } from 'ui/components/TextField/TextField';
import {
  ItemInventory,
  getItemInventory,
  SerialRow,
  TrackingGroup,
  InventoryRow,
  itemHasTracking,
  itemHasOnlySerialTracking,
  initialItemInventory,
  itemHasSerialTracking,
} from 'services/inventory';
import { Location } from 'services/locations';
import { Errors, validateYup } from 'services/forms/validation';
import { useHandleTextFieldChange } from 'services/forms';
import { replaceValueInCollection } from 'helpers';
import { COMPANY_WIDE_ID } from 'services/locations/consts';
import { getUoms, EACH_UOM_ID } from 'services/uoms';
import { pickItemIsInventoryType } from 'services/picking';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';

import { PickItemFinishDetailsProps } from './types';
import { pickingTrackingTableValidationSchema } from '../../../../validations';
import TrackingTable from 'ui/components/Table/TrackingTable/TrackingTable';
import SerialTable from 'ui/components/Table/SerialTable/SerialTable';
import { colorPalette } from 'ui/theme';

const FBOPickItemFinishDetails: React.FC<PickItemFinishDetailsProps> = (
  props
) => {
  const {
    pickItem,
    setPickItem,
    pickItems,
    trackingTableErrors,
    setTrackingTableErrors,
    setIsLoading,
  } = props;

  const { items: uoms } = useSelector(getUoms);

  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);
  const [amountError, setAmountError] = useState<string | null>(null);

  const locationQuantity: {
    [locationId: number]: number;
  } = useMemo(
    () =>
      itemInventory.inventoryRowList.reduce(
        (acc, i) => ({ ...acc, [i.locationId]: i.availableToPickQty }),
        {}
      ),
    [itemInventory]
  );

  const pickItemUom = useMemo(
    () => uoms.find((u) => u.id === (pickItem.uomId || EACH_UOM_ID))!,
    [uoms, pickItem.uomId]
  );

  const hasTracking = useMemo(
    () => !!pickItem.item && itemHasTracking(pickItem.item),
    [pickItem.item]
  );

  const hasSerial = useMemo(
    () => pickItem.item && itemHasSerialTracking(pickItem.item),
    [pickItem]
  );

  const isInventoryType = pickItemIsInventoryType(pickItem);

  const hasOnlySerial = useMemo(
    () => !!pickItem.item && itemHasOnlySerialTracking(pickItem.item),
    [pickItem]
  );

  const isAllowedDecimal = useMemo(
    () => !!pickItem.item && !itemHasSerialTracking(pickItem.item),
    [pickItem]
  );

  const onlySerialData: SerialRow[] = useMemo(() => {
    return _.get(pickItem, 'trackingGroupList[0].serialList', []);
  }, [pickItem]);

  const selectedSerialRows: number[] = useMemo(() => {
    return _.get(pickItem, 'trackingGroupList[0].serialIds', []);
  }, [pickItem]);

  const calculateAvailableQty = useCallback(
    (inventory: ItemInventory, pickFromLocationId: number | null) => {
      if (!pickFromLocationId || pickFromLocationId === COMPANY_WIDE_ID) {
        return inventory.availableToPickQty;
      }

      const locationInventory = inventory.inventoryRowList.find(
        (row) => row.locationId === pickFromLocationId
      );

      if (locationInventory) {
        // find same item with same location
        const splittedItemsAmount = pickItems
          .filter((i) => i.itemId === pickItem.itemId)
          .reduce((acc, i) => acc + (i.amount || 0), 0);

        return Math.max(
          locationInventory.availableToPickQty - splittedItemsAmount,
          0
        );
      }

      return 0;
    },
    [pickItems, pickItem.itemId]
  );

  useEffect(() => {
    if (!isInventoryType) {
      setItemInventory(initialItemInventory);
      return;
    }

    (async () => {
      setIsLoading(true);
      const inventory = await getItemInventory(pickItem.itemId!);
      setItemInventory(inventory);

      const locationInventory = inventory.inventoryRowList.find(
        (row) => row.locationId === pickItem.pickFromLocationId
      );

      const quantityAvailable = calculateAvailableQty(
        inventory,
        pickItem.pickFromLocationId
      );

      if (_.isEmpty(pickItem.trackingGroupList)) {
        setPickItem((i) => ({
          ...i,
          quantityAvailable,
          trackingGroupList: locationInventory
            ? locationInventory.trackingGroupList
            : [],
          amount:
            i.amount || Math.min(pickItem.quantity || 0, quantityAvailable),
        }));
      }

      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickItem.id]);

  const handleLocationChange = useCallback(
    (value: Location | null) => {
      let locationInventory: InventoryRow | null = null;
      if (itemInventory && value) {
        locationInventory =
          itemInventory.inventoryRowList.find(
            (row) => row.locationId === value.id
          ) || null;
      }

      const quantityAvailable = calculateAvailableQty(
        itemInventory,
        value ? value.id : null
      );

      setPickItem((i) => ({
        ...i,
        pickFromLocationId: value ? value.id : null,
        quantityAvailable,
        trackingGroupList: locationInventory
          ? locationInventory.trackingGroupList
          : [],
      }));
    },
    [itemInventory, setPickItem, calculateAvailableQty]
  );

  const handleTextFieldChanged = useHandleTextFieldChange(
    setPickItem,
    pickItem
  );

  const handleValidation = useCallback(
    (trackingGroupList: TrackingGroup[]) => {
      setTrackingTableErrors([]);
      const resolvedValidation = hasSerial
        ? pickingTrackingTableValidationSchema
        : trackingTableValidationSchema;

      const errorArray: Errors[] = [];

      const customSetter = (newError: Errors) => errorArray.push(newError);

      trackingGroupList.forEach((tg) =>
        validateYup(tg, resolvedValidation, customSetter)
      );

      setTrackingTableErrors(errorArray);
    },
    [setTrackingTableErrors]
  );

  const handleSetTrackingGroups = useCallback(
    (trackingGroupList: TrackingGroup[]) => {
      const amount = trackingGroupList.reduce(
        (acc, trackingGroup) => acc + (trackingGroup.quantity || 0),
        0
      );

      handleValidation(trackingGroupList);

      setPickItem((i) => ({ ...i, amount, trackingGroupList }));
    },
    [hasSerial, setPickItem]
  );

  const handleSelectedSerialRowsChange = useCallback(
    (id: number | number[]) => {
      if (!pickItem) {
        return;
      }

      const trackingGroup = pickItem.trackingGroupList[0];
      let newSerialIds: number[] = [];

      if (Array.isArray(id)) {
        newSerialIds = id;
      } else {
        const isIdAlreadySelected = trackingGroup.serialIds.includes(id);
        newSerialIds = isIdAlreadySelected
          ? _.without(trackingGroup.serialIds, id)
          : [...trackingGroup.serialIds, id];
      }

      setPickItem({
        ...pickItem,
        amount: newSerialIds.length,
        trackingGroupList: replaceValueInCollection(
          pickItem.trackingGroupList,
          {
            ...trackingGroup,
            quantity: newSerialIds.length,
            serialIds: newSerialIds,
          },
          0
        )!,
      });
    },
    [pickItem, setPickItem]
  );

  const handleAmountChange = useCallback(
    (value: number | null) => {
      // disable validation on non inventory items
      if (isInventoryType) {
        setAmountError(
          value && value > (pickItem.quantityAvailable || 0)
            ? 'Quantity too large'
            : null
        );
      }

      setPickItem((prevPick) => ({ ...prevPick, amount: value }));
    },
    [setPickItem, pickItem.quantityAvailable, isInventoryType]
  );

  return (
    <>
      <Grid container spacing={2} disableEqualOverflow>
        {isInventoryType ? (
          <>
            <Grid xs={hasTracking ? 8 : 4}>
              <LocationsAsyncAutocomplete
                placeholder="Pick Origin"
                onChange={handleLocationChange}
                label="Pick Origin"
                value={pickItem ? pickItem.pickFromLocationId : null}
                dataQa="pick-finish-wizard-pick-origin"
                companyWide={false}
                renderOption={(props, option: Location) => {
                  return (
                    <li {...props}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography variant="body1">
                          {option.displayName}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {`${locationQuantity[option.id!] || 0} ${_.get(
                            pickItem,
                            'item.defaultUom.abbreviation',
                            ''
                          )}`}
                        </Typography>
                      </Box>
                    </li>
                  );
                }}
              />
            </Grid>
            {!hasTracking && (
              <Grid xs={4}>
                <TextFieldQuantity
                  label="Quantity"
                  name="amount"
                  required
                  autoComplete="off"
                  disableDebounce
                  value={pickItem.amount}
                  dataQa="pick-finish-wizard-item-quantity"
                  selectedUomId={pickItem.uomId || EACH_UOM_ID}
                  uoms={uoms}
                  onTextChange={handleAmountChange}
                  isDecimal={isAllowedDecimal}
                  error={Boolean(amountError)}
                  helperText={amountError}
                />
              </Grid>
            )}
            <Grid xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                label="Quantity At Loc."
                value={`${pickItem.quantityAvailable} ${pickItemUom?.abbreviation}`}
                readOnly
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                className="redesign"
                variant="standard"
                placeholder="Notes"
                multiline
                rows={3}
                value={pickItem.notes}
                name="notes"
                label="Notes"
                dataQa="pick-finish-wizard-item-notes"
                onChange={handleTextFieldChanged}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={4}>
              <TextFieldQuantity
                label="Quantity"
                name="amount"
                required
                autoComplete="off"
                disableDebounce
                value={pickItem.amount}
                uoms={[]}
                selectedUomId={EACH_UOM_ID}
                onTextChange={handleAmountChange}
                error={Boolean(amountError)}
                helperText={amountError}
              />
            </Grid>
            <Grid xs={8}>
              <TextField
                className="redesign"
                variant="standard"
                placeholder="Notes"
                value={pickItem.notes}
                name="notes"
                label="Notes"
                onChange={handleTextFieldChanged}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid xs={12}>
        {hasTracking && (
          <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
            maxHeight="50vh"
          >
            {!hasOnlySerial ? (
              <TrackingTable
                itemTrackingTypes={_.get(
                  pickItem,
                  'item.itemTrackingTypeList',
                  []
                )}
                trackingGroups={pickItem.trackingGroupList}
                tableType={TrackingTableTypes.Remove}
                disableAutoAssign
                onSetTrackingGroups={handleSetTrackingGroups}
                firstColumnTitle="Quantity To Commit"
                errors={trackingTableErrors}
                isDecimal={isAllowedDecimal}
                emptyTableText="No inventory available at this location. Please select another."
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                  borderBottom: 'none',
                  maxHeight: '270px',
                }}
                shouldSort
                recalculateErrorsAfterSearch
                handleValidation={handleValidation}
              />
            ) : (
              <SerialTable
                title="Tracking"
                serialList={onlySerialData}
                itemTrackingTypes={_.get(
                  pickItem,
                  'item.itemTrackingTypeList',
                  []
                )}
                status={
                  pickItem.autoAssign
                    ? SerialTableStatus.AutoSelected
                    : undefined
                }
                canSelectRow
                selectedRows={selectedSerialRows}
                onSelectedRowsChanged={handleSelectedSerialRowsChange}
                emptyTableText="No inventory available at this location. Please select another."
                disableAutoAssign
                sx={{
                  borderRadius: '5px',
                  borderLeft: `1px solid ${colorPalette.redesign.background3}`,
                  borderRight: `1px solid ${colorPalette.redesign.background3}`,
                  maxHeight: '270px',
                }}
              />
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

export default memo(FBOPickItemFinishDetails);
