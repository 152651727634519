import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { useFlags } from 'helpers/useFlags';

import { Page } from 'ui/components/Page/Page';
import { fetchTaxRates } from 'services/taxRates';
import { fetchSettingsCompanies } from 'services/settings/company';

import { CardSection } from './components';
import { QuickBooksCard } from './components/QuickBooks';
import { ShippoCard } from './components/Shippo';
import { useIntegrationsPageStyle } from './styled';
import { IntegrationsPageCmp, IntegrationsPageProps } from './types';
import { Routes } from '../../navigation';
import { XeroCard } from './components/Xero';

const IntegrationsPage: IntegrationsPageCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: IntegrationsPageProps
) => {
  const classes = useIntegrationsPageStyle();

  const dispatch = useDispatch();

  const flags = useFlags();

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchTaxRates());
    // @ts-ignore
    dispatch(fetchSettingsCompanies());
  }, [dispatch]);
  return (
    <>
      <Page>
        <Box className={classes.cardContainer}>
          <CardSection title="Accounting" sectionId="accounting">
            <QuickBooksCard />
            {flags.xero ? <XeroCard /> : null}
            {/* <QuickBooksDesktop /> */}
          </CardSection>

          <CardSection title="Shipping" sectionId="shipping">
            <ShippoCard />
          </CardSection>
        </Box>
      </Page>
    </>
  );
};

IntegrationsPage.route = Routes.IntegrationsPage;

export default IntegrationsPage;
