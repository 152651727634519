import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ItemsTable, Column } from 'ui/components/Table/ItemsTable';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { getSettingsCompany } from 'services/settings/company';
import { Pagination } from 'services/search';
import { Item } from 'services/items';
import { ItemInventoryEvents } from 'services/inventory';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';
import {
  ITEM_COLUMNS_ALWAYS_ON,
  ITEM_COLUMNS_ALWAYS_ON_ADJUST_SIZES,
  ITEM_COLUMNS_HIDABLE,
} from '../../consts';
import { ItemSearchResultsProps } from './types';
import { default as ItemRow } from './ItemRow';
import { createInventorySearchActionBarOptions } from './consts';
import { useFlags } from 'helpers/useFlags';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { IconNames } from 'ui/theme';

const ItemsSearchResults = (props: ItemSearchResultsProps) => {
  const {
    activeItemId,
    handleItemClick,
    items,
    isLoadingItems,
    onPaginationChange,
    pagination,
    onRowAction,
    onInventoryPageAction,
    setShowRopWizard,
    setShowQuickTransferModal,
  } = props;

  const companySettings = useSelector(getSettingsCompany);
  const flags = useFlags();
  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const initialResolvedColumns = activeItemId
    ? ITEM_COLUMNS_HIDABLE
    : ITEM_COLUMNS_ALWAYS_ON;

  const [resolvedColumns, setResolvedColumns] = useState<Column[]>(
    initialResolvedColumns
  );

  useEffect(() => {
    const adjustColumnSize = () => {
      const isViewportLargeDown = () =>
        window.matchMedia('(max-width: 90em)').matches; // 1440px

      const chooseColumnSet = () => {
        let columnsToReturn;

        if (
          companySettings.accountingMethod !== CostingMethod.Average &&
          !activeItemId
        ) {
          columnsToReturn = ITEM_COLUMNS_ALWAYS_ON.filter(
            (c) => c.title !== 'Cost'
          );
        } else if (activeItemId) {
          columnsToReturn = ITEM_COLUMNS_HIDABLE;
        } else if (!activeItemId && isViewportLargeDown()) {
          columnsToReturn = ITEM_COLUMNS_ALWAYS_ON_ADJUST_SIZES;
        } else {
          columnsToReturn = ITEM_COLUMNS_ALWAYS_ON;
        }

        return columnsToReturn;
      };

      setResolvedColumns(chooseColumnSet());
    };

    adjustColumnSize();
    window.addEventListener('resize', adjustColumnSize);

    return () => window.removeEventListener('resize', adjustColumnSize);
  }, [activeItemId, companySettings.accountingMethod]);

  const handleRowAction = ({
    item,
    type,
  }: {
    item: Item;
    type: ItemInventoryEvents;
  }) => {
    onRowAction(item, type);
  };

  const actionBarOptions = () =>
    createInventorySearchActionBarOptions(onInventoryPageAction);

  return (
    <>
      <FBOTitleBar title="Inventory">
        {!activeItemId && (
          <>
            <FBOButton
              style={{ marginRight: '8px' }}
              variant="secondary"
              color="positive"
              size="medium"
              icon={IconNames.FBOQuickTransfer}
              onClick={() => setShowQuickTransferModal(true)}
              data-qa="inventory-quick Transfer-button"
            >
              Bulk Move
            </FBOButton>

            {flags.forecasting && (
              <FBOButton
                style={{ marginRight: '8px' }}
                variant="secondary"
                color="positive"
                size="medium"
                onClick={() => setShowRopWizard(true)}
                data-qa="inventory-forecasting-button"
              >
                Forecasting
              </FBOButton>
            )}
          </>
        )}

        <FBOMenuButton
          variant="secondary"
          items={actionBarOptions()}
          data-qa="inventory-dot-menu"
        />
      </FBOTitleBar>
      <ItemsTable
        data={items}
        columns={resolvedColumns}
        activeId={activeItemId}
        onItemClick={handleItemClick}
        selectableItems={false}
        RenderCustomRow={ItemRow}
        isLoadingData={isLoadingItems}
        pagination={pagination}
        tableLayoutFixed
        meta={{ companySettings }}
        onPaginationChange={handlePaginationChange}
        onAction={handleRowAction}
        dataQa="inventory-item-table"
        showEnhancedTablePaginationActions={!Boolean(activeItemId)}
        emptyTableText=""
      />
    </>
  );
};
export default memo(ItemsSearchResults);
