import {
  refundSalesOrderPayment,
  SalesOrderPayment,
} from 'services/salesOrders';

export const refundPayment = async (
  payment: SalesOrderPayment,
  amount: number
) => {
  const { salesOrderId, id: paymentId, data } = payment;

  if (data.type === 'stripe') {
    return;
  }

  await refundSalesOrderPayment(salesOrderId!, paymentId!, amount);
};
