import { VendorMinMessage } from './consts';

export const validateQuantity = (value: string, row: any) => {
  if (
    value &&
    row.vendorMinimumDetail?.quantity &&
    value < row.vendorMinimumDetail.quantity
  ) {
    return VendorMinMessage.QuantityBelowVendorMinimum;
  }
  return '';
};
