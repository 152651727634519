import { IconNames } from 'ui/theme';
import { MenuItem } from 'ui/components/Menu';

export enum InventoryPageAction {
  ImportInventory,
  ExportInventory,
  ExportAddInventorySample,
  ExportCycleInventorySample,
  ShowReport,
}

export const createInventorySearchActionBarOptions = (
  onActionClick: (actionType: InventoryPageAction) => void
): MenuItem[] => [
  {
    label: 'Show Report',
    onOptionClick: () => onActionClick(InventoryPageAction.ShowReport),
    icon: IconNames.FBOReportSmall,
    separateAfter: true,
  },
  {
    label: 'Import Inventory',
    onOptionClick: () => onActionClick(InventoryPageAction.ImportInventory),
    icon: IconNames.FBOImport,
    iconRight: IconNames.FBOQuestionMarkInfo,
    iconRightHref:
      'https://help.fishbowlonline.com/s/article/Drive-Inventory#Import_inventory',
    separateAfter: true,
  },
  {
    label: 'Export Inventory Data',
    onOptionClick: () => onActionClick(InventoryPageAction.ExportInventory),
    icon: IconNames.FBOExport,
    separateAfter: true,
  },

  {
    label: 'Export Add Inventory Sample',
    onOptionClick: () =>
      onActionClick(InventoryPageAction.ExportAddInventorySample),
    icon: IconNames.FBOExport,
    separateAfter: true,
  },
  {
    label: 'Export Cycle Inventory Sample',
    onOptionClick: () =>
      onActionClick(InventoryPageAction.ExportCycleInventorySample),
    icon: IconNames.FBOExport,
  },
];
