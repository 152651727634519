import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import _ from 'lodash';

import { TabPanel } from 'ui/components/TabPanel';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import {
  cancelSalesOrder,
  deleteSalesOrder,
  fetchSalesOrderById,
  getSalesOrderId,
  initialSalesOrder,
  issueSalesOrder,
  quickFulfillSalesOrder,
  restoreSalesOrder,
  SalesOrder,
  SalesOrderItemTypes,
  SalesOrderPaymentStatus,
  SalesOrderStatus,
  unIssueSalesOrder,
} from 'services/salesOrders';
import { showNotification } from 'services/api/notifications';
import { getActiveUser } from 'services/user';
import { Customer, getCustomers } from 'services/customers';
import { ReportId } from 'services/reports';
import { getSettingsCompany } from 'services/settings/company/redux';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';
import { Errors } from 'services/forms/validation';
import { useUrlQueryObject } from 'services/url';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { updateTaxesFromTaxJar } from 'services/integrations/other/taxjar';
import { getSettingsShipping } from 'services/settings/shipping/redux';
import { FulfillServiceAndMiscItemsOnShip } from 'services/settings/shipping';
import { ItemType } from 'services/items';

import {
  SalesOrderActiveIdState,
  SalesOrderDetailsCardCmp,
  SalesOrderDetailsCardProps,
  SalesOrderTab,
} from './types';
import {
  SalesOrderDetailsTabBeta,
  SalesOrderDocumentsTab,
  SalesOrderNotesTab,
  SalesOrderEmailModal,
  SalesOrderItems,
  SalesOrderPaymentsModal,
} from './components';
import {
  saveSalesOrder,
  transformToDuplicateAsCreditReturn,
  transformToDuplicatedItem,
  validateSalesOrder,
} from './helpers';
import { getErrorMessage, useLongPolling } from 'helpers';
import { SalesOrderShippingQuoteModal } from './components/SalesOrderShippingQuoteModal';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  updateModuleNavigation,
} from 'services/moduleNavigation';
import { SalesOrderTitleBar } from '../SalesOrderTitleBar';
import SalesOrderDetailsCardWithServiceModule from 'Sales/components/SalesOrderDetails';
import { CreatePurchaseOrderWizard } from './components/CreatePurchaseOrderWizard';

import { useFlags } from 'helpers/useFlags';
import { logErrorCtx } from 'app/logging';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Routes } from 'ui/modules/sales/navigation';
import { FetchOptions } from 'ui/components/Page/WithSearchResults';
import { LongPolling } from 'ui/components/Dialog/LongPolling';
import { DismissibleModal } from 'ui/components/Modal/DismissableModal/DismissibleModal';
import { noTaxAddedBodyContent, noTaxAddedModalTitle } from './consts';
import { getCurrencyById } from 'services/currencies/api';
import SalesOrderGeneralTab from './components/SalesOrderGeneralTab/SalesOrderGeneralTab';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const SalesOrderDetailsCard: SalesOrderDetailsCardCmp = (
  props: SalesOrderDetailsCardProps
) => {
  const {
    activeSalesOrderId,
    nextSalesOrdersNumber,
    setNextSalesOrderNumber,
    onClose,
    fetchSearchResult,
    clone,
    setClone,
  } = props;

  const flags = useFlags();

  const dispatch = useDispatch();
  const history = useHistory();

  const { items: customers } = useSelector(getCustomers);
  const companySettings = useSelector(getSettingsCompany);
  const soSettings = useSelector(getSettingsSalesOrder);
  const activeUser = useSelector(getActiveUser);
  const shippingSettings = useSelector(getSettingsShipping);
  const [showNoTaxAddedModal, setshowNoTaxAddedModal] =
    useState<boolean>(false);

  const [, setQueryParams] = useUrlQueryObject();

  const [activeSalesOrder, setActiveSalesOrder] =
    useState<SalesOrder>(initialSalesOrder);
  const [activeTab, setActiveTab] = useState(SalesOrderTab.General);
  const [rowValidationErrors, setRowValidationErrors] = useState<Errors[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [paymentsModalVisible, setPaymentsModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [shippingQuoteModalVisible, setShippingQuoteModalVisible] =
    useState(false);
  const [taxWarningModalVisible, setTaxWarningModalVisible] =
    useState<boolean>(false);
  const [paidWarningModalVisible, setPaidWarningModalVisible] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isPolling, notify, clearNotify, startPolling, stopPolling } =
    useLongPolling();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [showReportModal, setShowReportModal] = useState(false);
  const [showCreatePOModal, setShowCreatePOModal] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [
    showQuickFulfillConfirmationModal,
    setShowQuickFulFillConfirmationModal,
  ] = useState(false);
  const oldState = useRef<SalesOrder | null>(initialSalesOrder);

  const connection = useSelector(getShippingConnection);

  const resolvedTitle = useMemo(() => {
    const customerDisplayName = selectedCustomer
      ? ` - ${selectedCustomer.name}`
      : '';

    const salesOrderNumber = activeSalesOrder.id
      ? activeSalesOrder.number
      : nextSalesOrdersNumber;

    const copySuffix =
      activeSalesOrderId === SalesOrderActiveIdState.Duplicate ? ' Copy' : '';

    const title = salesOrderNumber + customerDisplayName + copySuffix;
    return title;
  }, [
    activeSalesOrder,
    nextSalesOrdersNumber,
    selectedCustomer,
    activeSalesOrderId,
  ]);

  const resolvedPaidStatus = useMemo(() => {
    if (!activeSalesOrder.paymentTotal || activeSalesOrder.paymentTotal === 0) {
      return SalesOrderPaymentStatus.Unpaid;
    }

    const isPaid =
      activeSalesOrder.grandTotal &&
      activeSalesOrder.paymentTotal &&
      activeSalesOrder.grandTotal <= activeSalesOrder.paymentTotal;

    return isPaid
      ? SalesOrderPaymentStatus.Paid
      : SalesOrderPaymentStatus.PartiallyPaid;
  }, [activeSalesOrder.paymentTotal, activeSalesOrder.grandTotal]);

  const shouldShowQuickFulFillModal = useMemo(() => {
    if (
      shippingSettings.fulfillServiceAndMiscItemsOnShip !==
      FulfillServiceAndMiscItemsOnShip.Confirm
    ) {
      return false;
    }

    // only show confirmation modal if ship has miscsale,service and note item
    return activeSalesOrder.salesItems.some((i) => {
      const itemType = _.get(i, 'saleItem.item.itemType');
      const salesOrderType = _.get(i, 'salesOrderItemType');
      return (
        itemType === ItemType.Service ||
        salesOrderType === SalesOrderItemTypes.MiscSale ||
        salesOrderType === SalesOrderItemTypes.Note
      );
    });
  }, [shippingSettings.fulfillServiceAndMiscItemsOnShip, activeSalesOrder]);

  const duplicateClicked = useCallback(async () => {
    // We use id -2 to duplicate
    const nextNumber = await getSalesOrderId();
    setNextSalesOrderNumber(nextNumber);
    setQueryParams({ activeId: SalesOrderActiveIdState.Duplicate });
  }, [setQueryParams, setNextSalesOrderNumber]);

  const asyncFc = useCallback(
    async (id: number) => {
      setIsLoading(true);
      try {
        const salesOrder = await fetchSalesOrderById(
          id,
          flags.expandsOptimization
        );

        if (salesOrder?.customer?.currencyId) {
          salesOrder.customer.currency = await getCurrencyById(
            salesOrder.customer.currencyId
          );
        }

        oldState.current = salesOrder;
        setActiveSalesOrder(salesOrder);
        if (clone) {
          duplicateClicked();
        }
      } catch (e) {
        const message = getErrorMessage(e);
        logErrorCtx('Error in fetchSalesOrderById', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'SalesOrderDetailsCard',
          title: 'Error in fetchSalesOrderById',
          description: `SalesOrder id ${id}`,
        });
        showNotification(`${message} - sales order couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();
        return;
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clone, duplicateClicked]
  );

  useEffect(() => {
    // When adding new sales order we want to prefill data
    if (
      !activeSalesOrderId ||
      activeSalesOrderId === SalesOrderActiveIdState.New
    ) {
      const newSalesOrder: SalesOrder = {
        ...initialSalesOrder,
        id: SalesOrderActiveIdState.New,
        number:
          nextSalesOrdersNumber === -1
            ? null
            : nextSalesOrdersNumber.toString(),
        locationId: _.get(activeUser, 'user.defaultLocationId', null),
        fobPointId: companySettings.defaultFobPointId,
        priorityId: companySettings.defaultPriorityId,
        shippingTermId: companySettings.defaultShippingTermId,
        salesTaxId: soSettings.salesTaxId,
        salesTax: soSettings.salesTax,
      };
      oldState.current = newSalesOrder;
      setActiveSalesOrder(newSalesOrder);
      return;
    }

    // When duplicating sales order we want to prefill data
    if (activeSalesOrderId === SalesOrderActiveIdState.Duplicate) {
      const newDuplicatedSalesOrder = transformToDuplicatedItem(
        activeSalesOrder,
        nextSalesOrdersNumber
      );
      setActiveSalesOrder(newDuplicatedSalesOrder);
      showNotification('You duplicated sales order', { variant: 'success' });
      setClone(false);
      return;
    }

    // When duplicating as credit return we want to change all purchase and misc purchase to credit return
    if (
      activeSalesOrderId === SalesOrderActiveIdState.DuplicateAsCreditReturn
    ) {
      const newDuplicatedAsCreditReturn =
        transformToDuplicateAsCreditReturn(activeSalesOrder);
      setActiveSalesOrder(newDuplicatedAsCreditReturn);
      showNotification('You duplicated sales order as credit return', {
        variant: 'success',
      });
      return;
    }

    asyncFc(activeSalesOrderId);
    setErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSalesOrderId]);

  // update spatial navigation
  useEffect(() => {
    if (
      !activeSalesOrder.id ||
      activeSalesOrder.id === SalesOrderActiveIdState.New ||
      activeSalesOrder.id === SalesOrderActiveIdState.Duplicate ||
      activeSalesOrder.id === SalesOrderActiveIdState.DuplicateAsCreditReturn
    ) {
      dispatch(clearModuleNavigation(ModuleNavigationType.Sales));
      return;
    }

    dispatch(
      updateModuleNavigation(ModuleNavigationType.Sales, {
        salesOrderIds: [activeSalesOrder.id],
        purchaseOrderId:
          (activeSalesOrder.dropShipPurchaseOrders.length &&
            activeSalesOrder.dropShipPurchaseOrders[0].id) ||
          undefined,
        pickIds: activeSalesOrder.picks.map((p) => p.id),
        shipIds: activeSalesOrder.shipments.map((s) => s.id),
        receiptId: activeSalesOrder.receipt
          ? activeSalesOrder.receipt.id
          : undefined,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSalesOrder.status, activeSalesOrder.id, activeSalesOrder.receipt]);

  useEffect(() => {
    if (activeSalesOrder.customerId) {
      setSelectedCustomer(
        customers.find((c) => c.id === activeSalesOrder.customerId)!
      );
    } else {
      setSelectedCustomer(null);
    }
  }, [activeSalesOrder.customerId, customers]);

  useEffect(() => {
    if (selectedCustomer && selectedCustomer.taxExempt) {
      setActiveSalesOrder((old) => ({
        ...old,
        salesTaxId: null,
        salesTax: null,
      }));
      if (oldState.current) {
        oldState.current.salesTaxId = null;
        oldState.current.salesTax = null;
      }
    } else if (!activeSalesOrder.salesTaxId) {
      setActiveSalesOrder((old) => ({
        ...old,
        salesTaxId: soSettings.salesTaxId,
        salesTax: soSettings.salesTax,
      }));
    }
  }, [selectedCustomer, activeSalesOrderId, soSettings]);

  const hideDeleteModal = useCallback(() => setDeleteModalVisible(false), []);

  const handleActiveTabChange = useCallback(
    async (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const onDeleteClicked = useCallback(() => setDeleteModalVisible(true), []);

  const duplicateAsCreditReturnClicked = useCallback(async () => {
    // We use id -3 to duplicate as credit return
    setQueryParams({
      activeId: SalesOrderActiveIdState.DuplicateAsCreditReturn,
    });
  }, [setQueryParams]);
  const handleDismissClick = useCallback(() => {
    setshowNoTaxAddedModal(false);
  }, [activeSalesOrder]);

  const handleSaveClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);
      const hasTaxableItemWithoutTaxRate = activeSalesOrder.salesItems.some(
        (item) => item.taxable && (item.taxRate === 0 || item.taxRate === null)
      );
      if (hasTaxableItemWithoutTaxRate) {
        setshowNoTaxAddedModal(true);
      }
      const isValid = validateSalesOrder(
        activeSalesOrder,
        setErrors,
        setRowValidationErrors,
        setCustomFieldsErrors
      );

      if (!isValid) {
        setIsLoading(false);
        setActiveTab(SalesOrderTab.General);
        return false;
      }

      let newSalesOrder = null;

      try {
        newSalesOrder = await saveSalesOrder(
          activeSalesOrder,
          setActiveSalesOrder
        );
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Unable to save a sales order', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description: 'Validation error while saving a sales order',
          component: 'SalesOrderDetailsCard',
        });
        stopPolling();
        setIsLoading(false);
        return false;
      }

      oldState.current = newSalesOrder;

      await fetchSearchResult();

      if (close) {
        setQueryParams({ activeId: null });
        onClose();
      } else {
        setQueryParams({ activeId: newSalesOrder.id });
      }

      setIsLoading(false);

      return true;
    },
    [activeSalesOrder, fetchSearchResult, onClose, setQueryParams]
  );

  const handleDeleteConfirm = useCallback(async () => {
    oldState.current = activeSalesOrder;
    setIsLoading(true);

    handleDeleteClickedCallback(
      deleteSalesOrder,
      activeSalesOrder,
      history,
      fetchSearchResult,
      setDeleteModalVisible,
      onClose
    );
    setIsLoading(false);
  }, [fetchSearchResult, onClose, activeSalesOrder]);

  const onPaymentsClicked = useCallback(async () => {
    if (
      activeSalesOrder.status === SalesOrderStatus.Fulfilled ||
      activeSalesOrder.status === SalesOrderStatus.Cancelled
    ) {
      setPaymentsModalVisible(true);
      return;
    }
    try {
      const isSaveValid = await handleSaveClicked();
      if (isSaveValid) {
        setPaymentsModalVisible(true);
      }
    } catch (error) {
      logErrorCtx('Error in onPaymentsClicked', {
        error: error as Error,
        stackTrace: (error as Error).stack,
        component: 'SalesOrderDetailsCard',
        title: 'Error in onPaymentsClicked',
        description: 'Error in onPaymentsClicked',
      });
    }
  }, [handleSaveClicked, activeSalesOrder.status]);

  const handlePaymentsModalOnClose = useCallback(() => {
    setPaymentsModalVisible(false);
  }, []);

  const handleIssue = useCallback(async () => {
    if (_.isEmpty(activeSalesOrder.salesItems.filter((i) => !i.deleted))) {
      showNotification('You cannot issue a Sales Order that has no items.', {
        variant: 'warning',
      });
      return;
    }

    const isValid = validateSalesOrder(
      activeSalesOrder,
      setErrors,
      setRowValidationErrors,
      setCustomFieldsErrors
    );

    if (!isValid) {
      setActiveTab(SalesOrderTab.General);
      return;
    }

    setIsLoading(true);

    let newSo = null;
    try {
      newSo = await saveSalesOrder(activeSalesOrder, setActiveSalesOrder, true);
      oldState.current = newSo;
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Save Sales Order Failed', {
        error,
        stackTrace: error.stack,
        title: 'Unable to Save Sales Order',
        description: error.message,
        component: 'SalesOrderDetailsCard',
      });
      setIsLoading(false);

      return;
    }

    try {
      newSo = await issueSalesOrder(newSo.id!);
      oldState.current = newSo;
      setActiveSalesOrder(newSo);
      await fetchSearchResult();
      setQueryParams({ activeId: newSo.id });
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Unable to Issue Sales Order', {
        error,
        stackTrace: error.stack,
        title: 'Failed to Issue Sales Order',
        description: error.message,
        component: 'SalesOrderDetailsCard',
      });
    }

    setIsLoading(false);
  }, [activeSalesOrder, fetchSearchResult, setQueryParams]);

  const onUnIssueClicked = useCallback(async () => {
    setIsLoading(true);
    let newSo = null;
    try {
      newSo = await saveSalesOrder(activeSalesOrder, setActiveSalesOrder, true);
      oldState.current = newSo;
    } catch {
      // Ignore error
      setIsLoading(false);
      return;
    }
    try {
      newSo = await unIssueSalesOrder(newSo.id!);
      oldState.current = newSo;
      setActiveSalesOrder(newSo);
      await fetchSearchResult();
      setQueryParams({ activeId: newSo.id });
    } catch {
      // Ignore error
    }
    setIsLoading(false);
  }, [activeSalesOrder, fetchSearchResult, setQueryParams]);

  const onIssueClicked = useCallback(() => {
    const taxModalVisible =
      !activeSalesOrder.salesTaxId && soSettings.verifyNoneTaxRate;

    if (taxModalVisible) {
      setTaxWarningModalVisible(true);
      return;
    }

    const paidModalVisible =
      resolvedPaidStatus ===
        (SalesOrderPaymentStatus.Unpaid ||
          SalesOrderPaymentStatus.PartiallyPaid) &&
      soSettings.orderNotPaidWarning;

    if (paidModalVisible) {
      setPaidWarningModalVisible(true);
      return;
    }

    handleIssue();
  }, [resolvedPaidStatus, activeSalesOrder, soSettings, handleIssue]);

  const quickFulfillOrder = useCallback(async () => {
    const isValid = validateSalesOrder(
      activeSalesOrder,
      setErrors,
      setRowValidationErrors,
      setCustomFieldsErrors
    );

    if (!isValid) {
      setActiveTab(SalesOrderTab.General);
      return;
    }

    startPolling();

    let newSo = null;
    try {
      newSo = await saveSalesOrder(activeSalesOrder, setActiveSalesOrder, true);
      oldState.current = newSo;
    } catch (e) {
      stopPolling();
      return;
    }

    try {
      newSo = await quickFulfillSalesOrder(newSo.id!);
      setActiveSalesOrder(newSo);
      oldState.current = newSo;
      await fetchSearchResult();
      setQueryParams({ activeId: newSo.id });
      // eslint-disable-next-line no-empty
    } catch {}

    stopPolling();
  }, [activeSalesOrder, fetchSearchResult, setQueryParams]);

  const onQuickFulfillClicked = useCallback(() => {
    if (shouldShowQuickFulFillModal) {
      setShowQuickFulFillConfirmationModal(true);
      return;
    }
    quickFulfillOrder();
  }, [shouldShowQuickFulFillModal, quickFulfillOrder]);

  const quickFulfillConfirmClicked = useCallback(() => {
    quickFulfillOrder();
    setShowQuickFulFillConfirmationModal(false);
  }, [quickFulfillOrder]);

  const handleTaxWarningModalContinue = useCallback(() => {
    const paidModalVisible =
      resolvedPaidStatus ===
        (SalesOrderPaymentStatus.Unpaid ||
          SalesOrderPaymentStatus.PartiallyPaid) &&
      soSettings.orderNotPaidWarning;

    setTaxWarningModalVisible(false);
    if (paidModalVisible) {
      setPaidWarningModalVisible(true);
    } else {
      handleIssue();
    }
  }, [resolvedPaidStatus, handleIssue, soSettings.orderNotPaidWarning]);

  const handleAddNewItemToSaleOrder = useCallback(() => {
    setShippingQuoteModalVisible(false);
    asyncFc(activeSalesOrderId!);
  }, [asyncFc, activeSalesOrderId]);

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreSalesOrder(activeSalesOrderId!);
        const restoredSalesOrder = await fetchSalesOrderById(
          activeSalesOrderId!,
          flags.expandsOptimization
        );
        oldState.current = restoredSalesOrder;
        setActiveSalesOrder(restoredSalesOrder);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activeSalesOrderId]
  );

  const cancelActiveSalesOrder = useCallback(async () => {
    setIsLoading(true);

    try {
      await cancelSalesOrder(activeSalesOrderId!);
      const newSalesOrder = await fetchSalesOrderById(
        activeSalesOrderId!,
        flags.expandsOptimization
      );
      oldState.current = newSalesOrder;
      setActiveSalesOrder(newSalesOrder);
    } catch {
      setIsLoading(false);
    }

    setIsLoading(false);

    fetchSearchResult();
  }, [fetchSearchResult, activeSalesOrderId]);

  const handleCancelSalesOrderClicked = useCallback(() => {
    if (!_.isEmpty(activeSalesOrder.payments)) {
      setCancelModalVisible(true);
      return;
    }

    cancelActiveSalesOrder();
  }, [activeSalesOrder.payments, cancelActiveSalesOrder]);

  const handleCancelConfirmClicked = useCallback(() => {
    cancelActiveSalesOrder();
    setCancelModalVisible(false);
  }, [cancelActiveSalesOrder]);

  const handleTaxWarningModalCancel = useCallback(() => {
    setTaxWarningModalVisible(false);
  }, []);

  const handlePaidWarningModalContinue = useCallback(() => {
    setPaidWarningModalVisible(false);
    handleIssue();
  }, [handleIssue]);

  const handlePaidWarningModalCancel = useCallback(() => {
    setPaidWarningModalVisible(false);
  }, []);

  const handleCalculateTaxesClicked = useCallback(async () => {
    const isValid = validateSalesOrder(
      activeSalesOrder,
      setErrors,
      setRowValidationErrors,
      setCustomFieldsErrors
    );

    if (!isValid) {
      setActiveTab(SalesOrderTab.General);
      return;
    }

    setIsLoading(true);
    let newSalesOrder = null;
    try {
      newSalesOrder = await saveSalesOrder(
        activeSalesOrder,
        setActiveSalesOrder
      );
      oldState.current = newSalesOrder;
      await fetchSearchResult();
      setQueryParams({ activeId: newSalesOrder.id });
    } catch (err) {
      logErrorCtx('Error in handleCalculateTaxesClicked saveSalesOrder', {
        error: err as Error,
        stackTrace: (err as Error).stack,
        component: 'SalesOrderDetailsCard',
        title: 'Error in handleCalculateTaxesClicked saveSalesOrder',
        description: 'Error in handleCalculateTaxesClicked saveSalesOrder',
      });
      setIsLoading(false);
      return;
    }

    if (!newSalesOrder || !newSalesOrder.id) {
      setIsLoading(false);
      return;
    }

    try {
      const updatedSO = await updateTaxesFromTaxJar(newSalesOrder.id!);
      setActiveSalesOrder(updatedSO);
      oldState.current = updatedSO;
      await fetchSearchResult();
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error while calculating taxes', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description:
          'Error while re-calculating taxes after discount change in Sales Order Detail',
        component: 'SalesOrderDetailCard',
      });
    }

    setIsLoading(false);
  }, [activeSalesOrder, fetchSearchResult, setQueryParams]);

  const onShippingQuoteClicked = useCallback(async () => {
    try {
      const isSaveValid = await handleSaveClicked();
      if (isSaveValid) {
        setShippingQuoteModalVisible(true);
      }
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Failed Saving Sales Order Details', {
        error,
        stackTrace: error.stack,
        title: 'Saving of Sales Order Details Failed',
        description: 'User Clicked `onShippingQuoteClicked` event handler',
        component: 'SalesOrderDetailsCard',
      });
    }
  }, [handleSaveClicked]);

  const closeLongPollingDialog = () => {
    clearNotify();
    history.push(`sales-order`);
  };

  const QuickFulfillDialogActions = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="space-evenly"
      sx={{ width: '100%', paddingLeft: '16px', paddingRight: '16px' }}
    >
      <FBOButton
        variant="secondary"
        color="positive"
        size="medium"
        onClick={() => setShowQuickFulFillConfirmationModal(false)}
        data-qa="quickFulfill-modal-cancel-btn"
        style={{
          flexGrow: 1,
          minWidth: '127px',
          marginRight: '16px',
        }}
      >
        Cancel
      </FBOButton>
      <FBOButton
        variant="primary"
        color="positive"
        size="medium"
        onClick={quickFulfillConfirmClicked}
        data-qa="quickFulfill-modal-fill-btn"
        style={{ flexGrow: 1, minWidth: '127px' }}
      >
        Fulfill
      </FBOButton>
    </Box>
  );

  return (
    <DetailsCard
      onSubmit={handleSaveClicked}
      isLoading={isLoading}
      isPolling={isPolling}
      state={activeSalesOrder}
      oldState={oldState}
    >
      <SalesOrderTitleBar
        activeSalesOrder={activeSalesOrder}
        connection={connection}
        resolvedTitle={resolvedTitle}
        soSettings={soSettings}
        onSave={handleSaveClicked}
        onClose={onClose}
        onIssueClicked={onIssueClicked}
        onUnIssueClicked={onUnIssueClicked}
        onQuickFulfillClicked={onQuickFulfillClicked}
        onPaymentsClicked={onPaymentsClicked}
        onDeleteClicked={onDeleteClicked}
        onShippingQuoteClicked={onShippingQuoteClicked}
        duplicateClicked={duplicateClicked}
        onUndeleteClicked={handleUndeleteClicked}
        onCancelClicked={handleCancelSalesOrderClicked}
        duplicateAsCreditReturnClicked={duplicateAsCreditReturnClicked}
        showReportModal={() => setShowReportModal(true)}
        showEmailModal={() => setShowEmailModal(true)}
        showCreatePOModal={() => setShowCreatePOModal(true)}
        resolvedPaidStatus={resolvedPaidStatus}
      />
      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
        className={'redesign'}
      >
        <Tab
          value={SalesOrderTab.General}
          label="General"
          data-qa="sales-order-general-tab"
        />
        <Tab
          value={SalesOrderTab.Details}
          label="Details"
          data-qa="sales-order-details-tab"
        />
        <Tab
          value={SalesOrderTab.Documents}
          label="Documents"
          data-qa="sales-order-documents-tab"
        />
        <Tab
          value={SalesOrderTab.Notes}
          label="Notes"
          data-qa="sales-order-notes-tab"
        />
      </Tabs>

      {/* General Tab */}
      <TabPanel
        value={activeTab}
        index={SalesOrderTab.General}
        flexGrow
        noSpacing
        style={{ flexDirection: 'column' }}
      >
        <>
          <SalesOrderGeneralTab
            salesOrder={activeSalesOrder}
            setSalesOrder={setActiveSalesOrder}
            validationErrors={errors}
            customFieldsErrors={customFieldsErrors}
            oldState={oldState}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          <SalesOrderItems
            salesOrder={activeSalesOrder}
            setSalesOrder={setActiveSalesOrder}
            validationErrors={errors}
            rowValidationErrors={rowValidationErrors}
            setIsLoading={setIsLoading}
            oldState={oldState}
            handleCalculateTaxesClicked={handleCalculateTaxesClicked}
          />
        </>
      </TabPanel>

      {/* Details Tab */}
      <TabPanel value={activeTab} index={SalesOrderTab.Details} noSpacing>
        <SalesOrderDetailsTabBeta
          salesOrder={activeSalesOrder}
          setSalesOrder={setActiveSalesOrder}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </TabPanel>

      {/* Documents Tab */}
      <TabPanel value={activeTab} index={SalesOrderTab.Documents} noSpacing>
        <SalesOrderDocumentsTab
          salesOrder={activeSalesOrder}
          setSalesOrder={setActiveSalesOrder}
        />
      </TabPanel>

      {/* Notes Tab */}
      <TabPanel value={activeTab} index={SalesOrderTab.Notes} noSpacing>
        <SalesOrderNotesTab
          salesOrder={activeSalesOrder}
          setSalesOrder={setActiveSalesOrder}
        />
      </TabPanel>

      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Sales Order"
        body={`This will delete '${
          activeSalesOrder.number || 'SALES_ORDER'
        }' and all related sales order items, are you sure?`}
        onCancelClicked={hideDeleteModal}
        onConfirmClicked={handleDeleteConfirm}
        confirmLabel={'Delete'}
        confirmButtonRed
      />
      <ConfirmationModal
        open={taxWarningModalVisible}
        title="Tax Rate Not Selected"
        body={`No tax rate has been selected. Are you sure you want to continue?`}
        onCancelClicked={handleTaxWarningModalCancel}
        onConfirmClicked={handleTaxWarningModalContinue}
        confirmLabel={'Continue'}
        confirmButtonRed
      />
      <ConfirmationModal
        open={cancelModalVisible}
        title="Cancel Sales Order"
        body={
          'You have taken payment for this order.  Would you still like to cancel?'
        }
        onCancelClicked={() => setCancelModalVisible(false)}
        onConfirmClicked={handleCancelConfirmClicked}
        confirmLabel={'Yes'}
        confirmButtonRed
      />
      <ConfirmationModal
        open={paidWarningModalVisible}
        title="Order Not paid"
        body={`This order has not been paid for. Are you sure you want to continue?`}
        onCancelClicked={handlePaidWarningModalCancel}
        onConfirmClicked={handlePaidWarningModalContinue}
        confirmLabel={'Continue'}
        confirmButtonRed
      />
      <ConfirmationModal
        open={showQuickFulfillConfirmationModal}
        title="Quick Fulfill"
        body="Do you want to fulfill service, miscellaneous and note items?"
        onCancelClicked={() => setShowQuickFulFillConfirmationModal(false)}
        onConfirmClicked={quickFulfillConfirmClicked}
        confirmLabel="Fulfill"
        DialogActionsComponent={QuickFulfillDialogActions}
      />
      <SalesOrderPaymentsModal
        salesOrder={activeSalesOrder}
        setSalesOrder={setActiveSalesOrder}
        visible={paymentsModalVisible}
        onClose={handlePaymentsModalOnClose}
        fetchSearchResult={fetchSearchResult}
        oldState={oldState}
      />
      <SalesOrderEmailModal
        salesOrder={activeSalesOrder}
        onClose={() => setShowEmailModal(false)}
        show={showEmailModal}
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.SalesOrder}
        params={{ salesOrderId: activeSalesOrderId }}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
      <SalesOrderShippingQuoteModal
        visible={shippingQuoteModalVisible}
        onClose={() => setShippingQuoteModalVisible(false)}
        activeSalesOrder={activeSalesOrder}
        onAddNewItemClick={handleAddNewItemToSaleOrder}
      />
      <CreatePurchaseOrderWizard
        visible={showCreatePOModal}
        salesOrder={activeSalesOrder}
        onClose={() => setShowCreatePOModal(false)}
      />
      <LongPolling
        open={notify}
        onClose={closeLongPollingDialog}
        title="Quick Fulfill Processing"
        content="Your order is processing. Check back later."
        dataQa="quickfulfill-proccessing-dialog"
      />
      <DismissibleModal
        open={showNoTaxAddedModal}
        title={noTaxAddedModalTitle}
        body={noTaxAddedBodyContent}
        onDismiss={handleDismissClick}
      />
    </DetailsCard>
  );
};

const MemoizedSalesOrderDetailCard = memo(SalesOrderDetailsCard);

export default function SalesOrderDetailsCardFlagSwitch(
  props: SalesOrderDetailsCardProps
) {
  const flags = useFlags();

  return flags.useSalesOrderServiceModule ? (
    <SalesOrderDetailsCardWithServiceModule {...props} />
  ) : (
    <MemoizedSalesOrderDetailCard {...props} />
  );
}

export const handleDeleteClickedCallback = async (
  deleteSalesOrder: (id: number) => Promise<any>,
  activeSalesOrder: SalesOrder,
  history: RouteComponentProps['history'],
  fetchSearchResult: (options?: FetchOptions) => Promise<void>,
  setDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  onClose: () => void
) => {
  try {
    await deleteSalesOrder(activeSalesOrder.id!);
    await fetchSearchResult();

    setDeleteModalVisible(false);
    history.push(Routes.SalesOrderPage);
    onClose();
  } catch (e) {
    logErrorCtx('Error in Deleting new Sales Order', {
      error: e as Error,
      component: 'SalesOrderDetailsCard',
    });
  }
};
