import React, { memo, useCallback, useMemo, useState } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';

import { User } from 'services/user';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { activeUserHasPermission } from 'services/user/redux';
import { PermissionType } from 'services/permissions';
import { showNotification } from 'services/api';
import { UsersAutocomplete } from 'ui/components/Autocomplete/UsersAutocomplete';

import { GROUP_USERS_COLUMNS } from './consts';
import { PermissionsItemsProps } from './types';
import { transformUserToPermissionUser } from './transformations';
import { usePermissionItemsStyle } from './styled';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette } from 'ui/theme';

const PermissionsItems: React.FC<PermissionsItemsProps> = (props) => {
  const { permissionGroup, setPermissionGroup } = props;

  const classes = usePermissionItemsStyle();

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const canEditPermissions =
    permissionGroup.id && permissionGroup.id > 0
      ? [PermissionType.PermissionEdit]
      : [PermissionType.PermissionCreate];

  const canAddUsers = useSelector(activeUserHasPermission(canEditPermissions));

  const groupUsersIds = useMemo(
    () => permissionGroup.users.map((u) => u.id!),
    [permissionGroup.users]
  );

  const handleDeleteSelectedClick = useCallback(() => {
    setPermissionGroup((old) => ({
      ...old,
      users: old.users.filter((u) => !selectedItems.includes(u.id!)),
    }));
    setSelectedItems([]);
  }, [selectedItems, setPermissionGroup]);

  const deleteTableRowButtonRestyle = () => {
    return (
      <FBOButton
        sx={{ marginLeft: '8px' }}
        variant="secondary"
        color="negative"
        size="medium"
        icon="TrashCan"
        data-qa="user-delete-button"
        permissions={canEditPermissions}
        key={1}
        onClick={handleDeleteSelectedClick}
      >
        Delete
      </FBOButton>
    );
  };

  const deleteTableRowButton = useMemo(
    () => (selectedItems.length ? [deleteTableRowButtonRestyle()] : []),
    [selectedItems, handleDeleteSelectedClick, canEditPermissions]
  );

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleAddUserClick = () => {
    if (selectedUser) {
      // check if user is already in this group
      const groupUsers = permissionGroup.users.map((u) => u.id);
      if (groupUsers.includes(selectedUser.id)) {
        showNotification('User is already in this group', {
          variant: 'error',
        });

        return;
      }

      setPermissionGroup((old) => ({
        ...old,
        users: [...old.users, transformUserToPermissionUser(selectedUser)],
      }));
      setSelectedUser(null);
    }
  };

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      overflow={'hidden'} // TODO : RESTYLING : FBOTitleBar needs "no padding" prop for inner-placements, along with border-prop
      sx={{
        borderTop: `1px solid ${colorPalette.redesign.background3}`,
      }}
    >
      <FBOTitleBar title="Users" beforeElements={deleteTableRowButton}>
        {/* TODO : RESTYLING : ADD ELEMENTS */}
        {canAddUsers && (
          <>
            <UsersAutocomplete
              sx={{ marginLeft: '8px' }}
              onChange={setSelectedUser}
              value={selectedUser}
              disableAdd
              fullWidth
              removedIds={groupUsersIds}
              placeholder="Search users"
              classes={{
                root: classes.searchInputOuter,
              }}
              additionalInputProps={{
                classes: {
                  root: classes.searchInputInner,
                  notchedOutline: classes.noBorder,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FBOButton
              variant="secondary"
              color="positive"
              size="medium"
              icon="FBOAddCircle"
              data-qa="permission-user-add-new"
              onClick={handleAddUserClick}
            >
              Add New
            </FBOButton>
          </>
        )}
      </FBOTitleBar>

      <ItemsTable
        data={permissionGroup.users}
        columns={GROUP_USERS_COLUMNS}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedItems}
        emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD'"
      />
    </Box>
  );
};

export default memo(PermissionsItems);
