import { Vendor } from 'services/vendors';
import { Customer } from 'services/customers';
import { Fob, Item } from 'services/items';
import { Uom } from 'services/uoms';
import { ItemReference } from 'services/api';
import { Location } from 'services/locations';
import { CustomField } from 'services/customFields';
import { Document } from 'services/documents';
import { TaxRate } from 'services/taxRates';

import { Currency } from './../currencies/types';

export interface PurchaseOrder {
  id: number | null;
  accountingClassId: number | null;
  buyer: any | null;
  buyerId: number | null;
  carrierId: number | null;
  carrierServiceId: number | null;
  costIncludesTax: boolean;
  customerSONumber: string | null;
  customFields: CustomField[];
  dateCreated: Date | null;
  dateLastModified: Date | null;
  dateFulfilled: Date | null;
  dateIssued: Date | null;
  dateScheduled: Date | null;
  deleted: boolean;
  documents: Document[];
  dropShipSalesOrder: DropShipSalesOrder | null;
  email: string | null;
  exchangeCurrencyId: number | null;
  exchangeCurrency: Currency | null;
  exchangeRate: number | null;
  fobPoint: Fob | null;
  fobPointId: number | null;
  grandTotal: number | null;
  homeCurrencyId: number | null;
  location: Location | null;
  locationId: number | null;
  multiCurrencyGrandTotal: number | null;
  notes: string | null;
  number: string | null;
  paymentTermId: number | null;
  phone: string | null;
  picks: ItemReference[];
  purchaseTax: TaxRate | null;
  purchaseTaxId: number | null;
  purchaseOrderItemList: PurchaseOrderItem[];
  receipt?: ItemReference;
  shipments: ItemReference[];
  shippingTermId: number | null;
  shipToAttention: string | null;
  shipToCity: string | null;
  shipToCompanyName: string | null;
  shipToCountry: string | null;
  shipToName: string | null;
  shipToPostalCode: string | null;
  shipToResidential: boolean;
  shipToState: string | null;
  shipToStreet: string | null;
  shipToStreet2: string | null;
  shipToVerified: boolean;
  status: PurchaseOrderStatus;
  vendor: Vendor | null;
  vendorAddressCity: string | null;
  vendorAddressCountry: string | null;
  vendorAddressName: string | null;
  vendorAddressPostalCode: string | null;
  vendorAddressState: string | null;
  vendorAddressStreet: string | null;
  vendorAddressStreet2: string | null;
  vendorAddressVerified: boolean;
  vendorAttention: string | null;
  vendorCompanyName: string | null;
  vendorId: number | null;
  vendorResidential: boolean;
  vendorSONumber: string | null;
  version: number | null;
}

export enum PurchaseOrderStatus {
  BidRequest = 'Bid Request',
  Issued = 'Issued',
  Received = 'Received',
  PartiallyReceived = 'Partially Received',
  Reconciled = 'Reconciled',
  PartiallyReconciled = 'Partially Reconciled',
  Fulfilled = 'Fulfilled',
  PartiallyFulfilled = 'Partially Fulfilled',
  ClosedShort = 'Closed Short',
  Voided = 'Voided',
  Expired = 'Expired',
  Picking = 'Picking',
  PartiallyPicked = 'Partially Picked',
  Picked = 'Picked',
}

export enum PurchaseOrderItemTypes {
  Purchase = 'Purchase',
  OutSourced = 'Out Sourced',
  CreditReturn = 'Credit Return',
  MiscPurchase = 'Misc. Purchase',
  MiscCredit = 'Misc. Credit',
  Note = 'Note',
  DropShip = 'Drop Ship',
}

export enum PurchaseOrderItemStatus {
  Entered = 'Entered',
  Received = 'Received',
  PartiallyReceived = 'Partially Received',
  Reconciled = 'Reconciled',
  PartiallyReconciled = 'Partially Reconciled',
  Fulfilled = 'Fulfilled',
  PartiallyFulfilled = 'Partially Fulfilled',
  ClosedShort = 'Closed Short',
  Voided = 'Voided',
  Picking = 'Picking',
  PartiallyPicked = 'Partially Picked',
  Picked = 'Picked',
}

export enum PurchaseOrderItemOrderType {
  Purchase = 'Purchase',
  Mixed = 'Mixed',
  CreditReturn = 'Credit Return',
}

export interface PurchaseOrderItem {
  id: number | null;
  customer: Customer | null;
  customerId: number | null;
  dateScheduled: Date | null;
  deleted: boolean;
  description: string | null;
  item: Item | null;
  itemId: number | null;
  lineNumber: number | null;
  multiCurrencyTotalCost: number | null;
  multiCurrencyUnitCost: number | null;
  name: string | null;
  notes: string | null;
  purchaseOrderId: number | null;
  status: PurchaseOrderItemStatus;
  purchaseOrderItemType: PurchaseOrderItemTypes;
  purchaseOrder: PurchaseOrder | null;
  quantity: number | null;
  quantityFulfilled: number | null;
  totalCost: number | null;
  isFiveDecimals: boolean;
  unitCost: number | null;
  uom: Uom | null;
  uomId: number | null;
  vendorItemName: string | null;
  version: number | null;
  exchangeCurrencyId: number | null;
  exchangeRate: number | null;
  exchangeCurrency: Currency | null;
  taxId: number | null;
  taxable: boolean;
  taxRate: number | null;
  taxTotal: number | null;
  multiCurrencyTaxTotal: number | null;
  subTotal: number | null;
  multiCurrencySubTotal: number | null;
  costIncludeTax: boolean;
}

export interface PurchaseOrderEmail {
  to: string | null;
  cc: string | null;
  bcc: string | null;
  subject: string | null;
  body: string | null;
}

export interface DropShipSalesOrder {
  id: number | null;
  number: string | null;
}

export interface AutoPurchaseOrderItem {
  id: number;
  vendorId: number | null;
  item: Item;
  quantity: number | null;
  defaultUom?: Uom | null;
  defaultCost?: number | null;
  vendorMinimumDetail?: VendorMinimumDetail | null;
}

export interface VendorMinimumDetail {
  vendorId: number | null;
  quantity: number | null;
  cost: number | null;
  uomId: number | null;
  hasVendorMinimum: boolean;
}
