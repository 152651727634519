import React, { memo, useCallback } from 'react';
import { Box } from '@mui/material';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';

import ItemsStepRow from './ItemsStepRow';
import { columns } from './consts';
import { ItemsStepProps } from './types';

const ItemsStep: React.FC<ItemsStepProps> = (props) => {
  const {
    items,
    setItems,
    selectedItems,
    setSelectedItems,
    selectedLocation,
    onLocationChange,
    errors,
  } = props;

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      onLocationChange(location);
      setSelectedItems([]);
    },
    [setSelectedItems, onLocationChange]
  );

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" p={1}>
      <Box p={1}>
        <LocationsAsyncAutocomplete
          value={selectedLocation ? selectedLocation.id : null}
          placeholder="Select Location"
          onChange={handleLocationChange}
          label="Reorder Location"
          parentId={null}
          companyWide={false}
        />
      </Box>
      <ItemsTable
        columns={columns}
        data={items}
        setData={setItems}
        selectedItems={selectedItems}
        onSelectedChange={handleSelectClick}
        RenderCustomRow={ItemsStepRow}
        tableLayoutFixed
        emptyTableText={
          selectedLocation
            ? 'No Items'
            : 'No Items. Please select a reorder location.'
        }
        rowErrors={errors}
        dataQa="auto-purchase-order"
        meta={{
          locationId: selectedLocation?.id,
        }}
      />
    </Box>
  );
};

export default memo(ItemsStep);
