import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { TableCell, Box, InputAdornment, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { TextField } from 'ui/components/TextField/TextField';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { VendorAutocomplete } from 'ui/components/Autocomplete/VendorAutocomplete';
import { getVendors, Vendor } from 'services/vendors';
import { ItemReorderPoint } from 'services/items';
import { InventoryLocationSummary } from 'services/inventory';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme';
import { useItemStepStyle } from './styled';
import { getUoms } from 'services/uoms';
import { VendorMinMessage } from './consts';
import { resolveQuantity } from '../../helpers';
import { validateQuantity } from './helper';
import { roundToDecimals } from 'helpers';

const ItemsStepRow: React.FC<RenderCustomRowProps> = (props) => {
  const { row, setData, index, errors, meta } = props;
  const { items: vendors } = useSelector(getVendors);
  const { items: uoms } = useSelector(getUoms);
  const [rowUpdate, setRowUpdate] = useState<any>({
    ...row,
    message: VendorMinMessage.QuantityIncreased,
  });

  const item = row.item;
  const itemUom = _.get(item, 'defaultUom.abbreviation', '');

  const classes = useItemStepStyle();

  const selectedVendor = useMemo(() => {
    if (row.vendorId) {
      return vendors.find((v) => v.id === row.vendorId) || null;
    }
    return null;
  }, [row, vendors]);

  const inventoryLocationSummary: InventoryLocationSummary | null = useMemo(
    () =>
      item.inventoryLocationSummaryList[0]
        ? item.inventoryLocationSummaryList[0]
        : null,
    [item.inventoryLocationSummaryList]
  );

  const reorderPoint: ItemReorderPoint | null = useMemo(
    () =>
      row.item.reorderPointsList[0] ? row.item.reorderPointsList[0] : null,
    [row.item.reorderPointsList]
  );

  const useVendorMinimum = useMemo(() => {
    return (
      row.vendorMinimumDetail?.hasVendorMinimum &&
      row.vendorMinimumDetail?.vendorId === row.vendorId
    );
  }, [row.vendorId]);

  useEffect(() => {
    const quantity = useVendorMinimum
      ? row.vendorMinimumDetail?.quantity
      : resolveQuantity(row.item, meta?.locationId);

    const uom = useVendorMinimum
      ? uoms.find((u) => u.id === row.vendorMinimumDetail?.uomId)
      : row.item.defaultUom;

    const cost =
      useVendorMinimum && row.vendorMinimumDetail?.cost
        ? roundToDecimals(row.vendorMinimumDetail?.cost || 0, 5)
        : row.defaultCost;

    const updateRow = {
      ...row,
      quantity,
      item: {
        ...row.item,
        defaultUomId: uom.id,
        cost,
      },
    };
    setData(updateRow, index);
    setRowUpdate({ ...rowUpdate, quantity, uom });
  }, [row.vendorId]);

  const handleVendorChange = useCallback(
    (vendor: Vendor | null) => {
      setData({ ...row, vendorId: vendor ? vendor.id : null }, index);
    },
    [row, index, setData]
  );

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ?? null;
    const message = (useVendorMinimum && validateQuantity(value, row)) || '';
    setRowUpdate({ ...rowUpdate, quantity: value, message });
    setData({ ...row, quantity: value }, index);
  };

  return (
    <>
      <TableCell>
        <Box>
          <ImageItemBox
            source={_.get(item, 'defaultImage', null)}
            name={_.get(item, 'name', null)}
            description={_.get(item, 'description', null)}
            dataQa="auto-order"
          />
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-onhand">
        <Box>
          {inventoryLocationSummary
            ? ` ${inventoryLocationSummary.totalQty} ${itemUom}`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-allocated">
        <Box>
          {inventoryLocationSummary
            ? ` ${
                inventoryLocationSummary.allocatedOnPurchaseOrders +
                inventoryLocationSummary.allocatedOnSalesOrders
              } ${itemUom}`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-available">
        <Box>
          {inventoryLocationSummary
            ? ` ${inventoryLocationSummary.availableQty} ${itemUom}`
            : ''}
        </Box>
      </TableCell>

      <TableCell data-qa="auto-order-incoming">
        <Box>
          {inventoryLocationSummary
            ? ` ${
                inventoryLocationSummary.qtyIncomingOnPurchaseOrders +
                inventoryLocationSummary.qtyIncomingOnSalesOrders
              } ${itemUom}`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-min">
        <Box>{reorderPoint ? reorderPoint.min : '-'}</Box>
      </TableCell>
      <TableCell data-qa="auto-order-max">
        <Box>{reorderPoint ? reorderPoint.max : '-'}</Box>
      </TableCell>
      <TableCell data-qa="auto-order-UOM">
        <Box>{rowUpdate.uom?.abbreviation}</Box>
      </TableCell>
      <TableCell>
        <Box>
          <TextField
            variant="standard"
            className="redesign"
            type="number"
            placeholder="Quantity"
            name="quantity"
            value={row.quantity}
            onChange={handleQuantityChange}
            error={!!errors.quantity}
            fullWidth
            dataQa="auto-order-to-order-value"
            InputProps={{
              startAdornment: useVendorMinimum && rowUpdate.message && (
                <InputAdornment position="end">
                  <Tooltip
                    title={rowUpdate.message}
                    placement="right"
                    sx={{ color: 'unset' }}
                  >
                    <div>
                      <Icon
                        name={IconNames.FBOWarningCircle}
                        className={classes.warningIcon}
                      />
                    </div>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <VendorAutocomplete
            value={selectedVendor}
            onChange={handleVendorChange}
            error={!!errors.vendorId}
            dataQa="auto-order-vendor"
            additionalInputProps={{
              sx: { maxLength: 25 },
            }}
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(ItemsStepRow);
